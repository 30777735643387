import React from 'react';
import { Layout } from '../../../components/layout/Layout';
import { InternalSearchCardType } from '../../../components/layout/InternalSearchMetaData';
import { useWindowLocation } from '../../../hooks/useWindowLocation';
import { CenterProductArticleType } from '../../../../../types/centerProductArticleType';
import { useServiceCalculator } from './context/ServiceCalculatorContext';
import { ManualRequest } from './manualRequest/ManualRequest';
import { Wizard } from './wizard/Wizard';
import { PriceOffer } from './priceOffer/PriceOffer';

interface ServiceCalculatorProps {
  centerId: number;
  centerProductArticle: CenterProductArticleType;
  productSlug: string;
  isMember: boolean;
}

export const ServiceCalculator = ({
  centerId,
  centerProductArticle,
  productSlug,
  isMember,
}: ServiceCalculatorProps) => {
  const windowPath = useWindowLocation();
  const { title, seoConfig, customOptions } = (centerProductArticle as CenterProductArticleType) || {};
  const { shouldShowAnOffer, shouldShowManualRequest } = useServiceCalculator();

  return (
    <Layout
      title={title}
      url={windowPath}
      gtmArgs={{
        page_type: 'NafCenterProductArticle',
        pageCategory: 'NafCenterProductArticle',
        contentId: productSlug,
      }}
      isHiddenFromSearch={seoConfig?.isHiddenFromSearch}
      seoTitle={seoConfig?.title || title}
      seoDescription={seoConfig?.introduction || undefined}
      internalSearchMetaData={{ cardType: InternalSearchCardType.Simple }}
    >
      {shouldShowAnOffer && <PriceOffer />}
      {shouldShowManualRequest && <ManualRequest documentId={customOptions[0]?.documentId} />}
      {!shouldShowAnOffer && !shouldShowManualRequest && (
        <Wizard isMember={isMember} centerId={centerId} centerProductArticle={centerProductArticle} />
      )}
    </Layout>
  );
};
