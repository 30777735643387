import React from 'react';
import { Checkbox } from '@naf/checkbox';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { Label } from '@naf/input';
import * as S from '../../Styles';
import { OptionItem } from '../../../../../../../types/ServiceCalculatorTypes';

export const Equipment = <T extends FieldValues>({
  control,
  name,
  equipments,
}: UseControllerProps<T> & { equipments: OptionItem[] }) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value } }) => (
      <S.StyledCheckboxArea>
        <Label>Ekstrautstyr (valgfritt)</Label>
        <S.StyledCheckboxContainer>
          {equipments?.map((equipment) => (
            <Checkbox
              key={equipment.value}
              className="checkbox"
              checked={equipment.isSelected}
              name={equipment.text}
              label={equipment.text}
              onChange={(e) => {
                const updatedArray = value.map((item) => {
                  if (item.text === e.name) {
                    // eslint-disable-next-line no-param-reassign
                    item.isSelected = !item.isSelected;
                  }

                  return item;
                });
                onChange(updatedArray);
              }}
            />
          ))}
        </S.StyledCheckboxContainer>
      </S.StyledCheckboxArea>
    )}
  />
);
