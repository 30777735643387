import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useServiceCalculatorApi } from '../../../../hooks/useServiceCalculatorApi';
import {
  WorkProposalForm,
  FeedbackForm,
  ServiceCalculatorContextProps,
  ServiceCalculatorProviderProps,
  VehicleForm,
} from './ServiceCalculatorContext.types';
import { ServiceCalculatorOfferResponse } from '../../../../../../types/ServiceCalculatorTypes';

const ServiceCalculatorContext = createContext<ServiceCalculatorContextProps | undefined>(undefined);

const ServiceCalculatorProvider = ({ children }: ServiceCalculatorProviderProps) => {
  const { getOfferFn, getOfferWithPossibleEquipmentFn, getOfferWithSelectedWorkProposalFn, sendFeedbackFormFn } =
    useServiceCalculatorApi();

  const [offer, setOffer] = useState<ServiceCalculatorOfferResponse>({
    isCompleted: false,
    isPossible: false,
    vehicleMaintenanceWizard: undefined,
  });

  const vehicleForm = useForm<VehicleForm>({
    mode: 'onChange',
    defaultValues: {
      licensePlateNumber: '',
      vehicleName: '',
      isEv: false,
      equipments: [],
    },
  });

  const workProposalForm = useForm<WorkProposalForm>({
    mode: 'onChange',
    defaultValues: {
      workProposal: -1,
    },
  });

  const feedbackForm = useForm<FeedbackForm>({
    mode: 'onChange',
    defaultValues: {
      comments: '',
    },
  });

  const [shouldShowAnOffer, setShouldShowAnOffer] = useState(false);
  const [shouldShowManualRequest, setShouldShowManualRequest] = useState(false);
  const [shouldShowFeedbackConfirmation, setShouldShowFeedbackConfirmation] = useState(false);

  const updateShouldShowAnOffer = useCallback((value: boolean) => {
    setShouldShowAnOffer(value);
  }, []);

  const updateShouldShowManualRequest = useCallback((value: boolean) => {
    setShouldShowManualRequest(value);
  }, []);

  const getOffer = useCallback(
    async (form: VehicleForm) => {
      try {
        const data = await getOfferFn({
          licensePlateNumber: form.licensePlateNumber,
          centerId: form.centerId,
          mileage: form.mileage,
        });
        if (!data) {
          showManualOffer();
          return;
        }

        setOffer(data);
        vehicleForm.setValue('equipments', data.vehicleMaintenanceWizard.possibleEquipment ?? []);
        setShouldShowAnOffer(
          data && data.isCompleted && data.isPossible && !!data.vehicleMaintenanceWizard.offerRequest,
        );
        setShouldShowManualRequest(
          data &&
            ((data.isCompleted && !data.isPossible) ||
              (data.isCompleted && data.isPossible && !data.vehicleMaintenanceWizard.offerRequest)),
        );
      } catch {
        showManualOffer();
      }
    },
    [getOfferFn, vehicleForm],
  );

  const getOfferWithPossibleEquipment = useCallback(
    async (form: VehicleForm) => {
      setOffer({
        ...offer,
        vehicleMaintenanceWizard: {
          ...offer.vehicleMaintenanceWizard,
          workProposalGroups: [],
        },
      });

      let offerRequest = offer.vehicleMaintenanceWizard;
      offerRequest = {
        ...offerRequest,
        possibleEquipment: form.equipments,
        nextAction: {
          ...offerRequest.nextAction,
          name: 'AddWorkProposalGroups',
        },
        workProposalGroups: [],
      };
      try {
        const data = await getOfferWithPossibleEquipmentFn(offerRequest);
        if (!data) {
          showManualOffer();
          return;
        }

        setOffer(data);
        setShouldShowAnOffer(
          data && data.isCompleted && data.isPossible && !!data.vehicleMaintenanceWizard.offerRequest,
        );
        setShouldShowManualRequest(
          data &&
            ((data.isCompleted && !data.isPossible) ||
              (data.isCompleted && data.isPossible && !data.vehicleMaintenanceWizard.offerRequest)),
        );
      } catch {
        showManualOffer();
      }
    },
    [getOfferWithPossibleEquipmentFn, offer],
  );

  const getOfferWithSelectedWorkProposal = useCallback(
    async (form: WorkProposalForm) => {
      let offerRequest = offer.vehicleMaintenanceWizard;
      offerRequest = {
        ...offerRequest,
        workProposalGroups: offerRequest.workProposalGroups.map((wp) => ({
          ...wp,
          isSelected: wp.value === form.workProposal,
        })),
      };
      try {
        const data = await getOfferWithSelectedWorkProposalFn(offerRequest);
        if (!data) {
          showManualOffer();
          return;
        }

        setOffer(data);
        setShouldShowAnOffer(
          data && data.isCompleted && data.isPossible && !!data.vehicleMaintenanceWizard.offerRequest,
        );
        setShouldShowManualRequest(
          data &&
            ((data.isCompleted && !data.isPossible) ||
              (data.isCompleted && data.isPossible && !data.vehicleMaintenanceWizard.offerRequest)),
        );
      } catch {
        showManualOffer();
      }
    },
    [getOfferWithSelectedWorkProposalFn, offer],
  );

  const sendFeedbackForm = useCallback(
    async (email: string, comments: string, vehicleText: string, documentId: string) => {
      try {
        const isSent = await sendFeedbackFormFn(email, comments, vehicleText, documentId);
        setShouldShowFeedbackConfirmation(isSent);

        return isSent;
      } catch (e) {
        setShouldShowFeedbackConfirmation(false);
        return false;
      }
    },
    [sendFeedbackFormFn],
  );

  const showManualOffer = () => {
    setShouldShowManualRequest(true);
    setShouldShowAnOffer(false);
  };

  const resetState = useCallback(() => {
    vehicleForm.reset();
    workProposalForm.reset();
    feedbackForm.reset();
    setOffer({
      isCompleted: false,
      isPossible: false,
      vehicleMaintenanceWizard: undefined,
    });
    setShouldShowAnOffer(false);
    setShouldShowManualRequest(false);
  }, [vehicleForm, workProposalForm, feedbackForm]);

  const resetOffer = useCallback(() => {
    vehicleForm.setValue('equipments', []);
    vehicleForm.setValue('isEv', false);
    vehicleForm.setValue('vehicleName', '');
    setOffer({
      isCompleted: false,
      isPossible: false,
      vehicleMaintenanceWizard: undefined,
    });
  }, [vehicleForm]);

  const contextValues = useMemo(
    () => ({
      offer,
      getOffer,
      getOfferWithPossibleEquipment,
      getOfferWithSelectedWorkProposal,
      resetState,
      resetOffer,
      shouldShowAnOffer,
      shouldShowManualRequest,
      updateShouldShowAnOffer,
      updateShouldShowManualRequest,
      vehicleForm,
      workProposalForm,
      feedbackForm,
      sendFeedbackForm,
      shouldShowFeedbackConfirmation,
    }),
    [
      offer,
      getOffer,
      getOfferWithPossibleEquipment,
      getOfferWithSelectedWorkProposal,
      resetState,
      resetOffer,
      shouldShowAnOffer,
      shouldShowManualRequest,
      updateShouldShowAnOffer,
      updateShouldShowManualRequest,
      vehicleForm,
      workProposalForm,
      feedbackForm,
      sendFeedbackForm,
      shouldShowFeedbackConfirmation,
    ],
  );

  return <ServiceCalculatorContext.Provider value={contextValues}>{children}</ServiceCalculatorContext.Provider>;
};

export default ServiceCalculatorProvider;

export const useServiceCalculator = () => {
  const context = useContext(ServiceCalculatorContext);
  if (!context) {
    throw new Error('useServiceCalculator must be used within a ServiceCalculatorProvider');
  }
  return context;
};
