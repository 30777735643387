import styled from 'styled-components';
import { Grid, GridCol, GridRow } from '@naf/grid';
import { nafColor } from '@nafcore/theme';
import { UserInteraction } from '@naf/user-interaction';
import { Input, Select } from '@naf/input';
import { breakpoints, spacing } from '@naf/theme';
import { MAX_WIDTH } from '../../../components/layout/Layout';
import CtaButton from '../../../components/ctaButton/CtaButton';

export const StyledWrapper = styled.div`
  box-sizing: border-box;
  max-width: ${MAX_WIDTH};
  margin: auto;
  padding: ${spacing.space120} ${spacing.space48} ${spacing.space80} ${spacing.space48};

  @media (max-width: ${breakpoints.l}) {
    padding-top: ${spacing.space120};
    padding-left: ${spacing.space24};
    padding-right: ${spacing.space24};
  }

  @media (max-width: ${breakpoints.m}) {
    padding-top: ${spacing.space80};
    padding-bottom: ${spacing.space40};
  }

  .full-width {
    flex-grow: 1;
    width: 100%;
    box-sizing: border-box;
  }
`;

export const StyledGrid = styled(Grid)`
  margin-top: 40px;
`;

export const StyledGridCol = styled(GridCol)`
  .row-gap {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }
`;

export const StyledGridRow = styled(GridRow)`
  grid-template-columns: 1fr;
  align-items: center;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 500px;
`;

export const StyledUserInteraction = styled(UserInteraction)`
  padding: 16px !important;
  padding-bottom: 4px !important;
  width: auto;

  h4 {
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 300 !important;
  }
`;

export const StyledUserInteractionError = styled(UserInteraction)`
  margin-top: 16px;
`;

export const StyledOutlinedUserInteraction = styled(UserInteraction)`
  margin-top: 32px;
  margin-bottom: 16px;
  padding: 24px !important;
  background-color: white;
  border: 1px solid black;

  span {
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
  }
`;

export const StyledInnerColContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledSelect = styled(Select)`
  margin-bottom: 8px;

  div {
    flex-grow: 1;
    width: 100%;
    box-sizing: border-box;
  }

  div[class*='ListContainer'] {
    max-height: 190px;
    overflow-y: scroll;
  }
`;

export const StyledCheckboxContainer = styled.div`
  display: flex;
  column-gap: 32px;

  .checkbox {
    padding: 10px;
    border: 1px solid #c1c1c1;
    border-radius: 2px;
  }
`;

export const StyledRadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 32px;
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledCheckboxArea = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLabelArea = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${breakpoints.s}) {
    label,
    div {
      width: 100%;
    }
  }
`;

export const StyledInput = styled(Input)`
  margin-bottom: 8px;
`;

export const StyledTextArea = styled.div`
  width: 100%;

  > div {
    width: 100%;
  }
`;

export const ButtonDivider = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
  border: 1px solid #c1c1c1;
`;

export const StyledOfferCard = styled.div`
  background-color: white;
  border: 1px solid #c1c1c1;
  border-radius: 8px;
  padding: 24px 16px;
  margin-top: 32px;
  margin-bottom: 16px;
  width: 50%;

  @media (max-width: ${breakpoints.s}) {
    width: unset;
  }
`;

export const StyledInnerContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  width: 50%;

  @media (max-width: ${breakpoints.m}) {
    width: 100%;
    margin-bottom: 16px;
  }
`;

export const StyledVehicleText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  h6 {
    margin: 0;
  }
`;

export const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const StyledTextBox = styled.div`
  padding: 16px;
  background-color: ${nafColor.primary.dew};
  border-radius: 8px;
  margin-bottom: 40px;
  margin-top: 40px;
`;

export const MobileWrapper = styled.div`
  display: none;

  @media (max-width: ${breakpoints.m}) {
    display: block;
  }
`;

export const DesktopWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${breakpoints.m}) {
    display: none;
  }
`;

export const StyledMobileHeader = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledConfirmationTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 70%;

  @media (max-width: ${breakpoints.m}) {
    width: 100%;
  }
`;

export const StyledConfirmationImageWrapper = styled.div`
  display: flex;
  max-height: 160px;
  flex: 0 0 30%;
  padding-top: 48px;

  @media (max-width: ${breakpoints.m}) {
    padding-top: 0;
  }
`;

export const StyledBottomButton = styled(CtaButton)`
  width: fit-content;

  @media (max-width: ${breakpoints.m}) {
    width: 100%;
  }
`;
