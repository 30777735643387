import React from 'react';
import { TextVariant } from '@naf/text';
import { Button } from '@naf/button';
import { useLocation } from 'react-router';

import { CenterProducts, DisplayStyle } from '../../../../../../types/centerArticleType';
import S from '../styles';
import { useGTMDataLayer } from '../../../../hooks/useGTMDataLayer';

export const StandardProductBlock = ({ product }: { product: CenterProducts }) => {
  const location = useLocation();
  const datalayer = useGTMDataLayer();
  return (
    <S.ProductCol s="12" m="12" l="8" xl="8">
      {product.memberPrice && (
        <S.TextWrapper>
          <S.InlineText variant={TextVariant.Header2}>
            Medlemspris: {product.memberPrice.campaignPrice || product.memberPrice.price},-
          </S.InlineText>
          {product.memberPrice.campaignPrice && (
            <S.InlineText variant={TextVariant.SubHeader}>{` (Før: ${product.memberPrice.price},-)`}</S.InlineText>
          )}
        </S.TextWrapper>
      )}
      {product.nonMemberPrice && (
        <S.TextWrapper>
          <S.InlineText variant={TextVariant.SubHeader}>
            Ikke medlem: {product.nonMemberPrice.campaignPrice || product.nonMemberPrice.price},-
          </S.InlineText>
          {product.nonMemberPrice.campaignPrice && (
            <S.InlineText variant={TextVariant.SubHeader}>{` (Før: ${product.nonMemberPrice.price},-)`}</S.InlineText>
          )}
        </S.TextWrapper>
      )}
      {product.booking && product.booking.data.href && product.booking.type === 'externalLink' && (
        <Button
          href={product.booking.data.href}
          onClick={() =>
            product?.booking?.data.href &&
            datalayer?.push({
              event: 'cta_click',
              cta_text: 'Finn ledig tid',
              cta_location: location.pathname,
              cta_url: product.booking.data.href,
              cta_hostname: new URL(product.booking.data.href).hostname,
            })
          }
          variant={product.displayStyle === DisplayStyle.WithPricePrimaryButton ? 'signature' : 'outline'}
          target={product.booking.data.blank ? '_blank' : '_self'}
        >
          Finn ledig tid
        </Button>
      )}
    </S.ProductCol>
  );
};
