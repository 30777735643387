import React, { useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Text, TextVariant } from '@naf/text';
import { ButtonLink } from '@naf/button-link';
import { GridCol } from '@naf/grid';

import S from './styles';
import { CenterProducts, DisplayStyle } from '../../../../../types/centerArticleType';
import { ProductPairBlock } from './components/ProductPair';
import { StandardProductBlock } from './components/StandardProduct';
import { nafCenterBasePath } from '../../../routers/NafCenterRouter';

interface Props {
  products: CenterProducts[];
  slug: string;
}

const Product = ({ products }: { products: CenterProducts[] }) => {
  if (products.length > 1) {
    /* EU kontroll og NAF test */
    return <ProductPairBlock products={products} />;
  }
  if (
    products[0].displayStyle === DisplayStyle.WithPricePrimaryButton ||
    products[0].displayStyle === DisplayStyle.WithPriceSecondaryButton
  ) {
    /* NAF senter product */
    return <StandardProductBlock product={products[0]} />;
  }
  if (products[0].displayStyle === DisplayStyle.WithoutPrice) {
    /* Oljeskift / Reparasjon og service */
    return null;
  }
  if (products.length === 1 && !products[0].displayStyle) {
    return null;
  }
  return null;
};

export const ProductPrice = ({ products, slug }: Props) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const LogIn = () =>
    useMemo(
      () => (
        <S.LogIn>
          <Text variant={TextVariant.BodyText}>Er du medlem? Logg inn for å få medlemsprisen når du bestiller.</Text>
          <ButtonLink
            onClick={() => {
              loginWithRedirect({
                appState: { returnTo: `/${nafCenterBasePath}/${slug}` },
              });
            }}
            text="Logg inn"
          />
        </S.LogIn>
      ),
      [],
    );

  if (
    products[0].displayStyle === DisplayStyle.WithPricePrimaryButton ||
    products[0].displayStyle === DisplayStyle.WithPriceSecondaryButton
  ) {
    return (
      <GridCol s="12" m="12" l="12" xl="12">
        <Product products={products} />
        {!isAuthenticated && <LogIn />}
      </GridCol>
    );
  }
  return null;
};
