import React from 'react';
import { useHistory } from 'react-router-dom';
import { Text, TextVariant } from '@naf/text';
import * as S from '../Styles';
import SentSuccessfully from '../../../../../assets/images/SentSuccessfully.svg';
import BreadCrumb from '../../../../components/breadcrumb/BreadCrumb';
import { funnelPayload } from '../../../../../../types/gtmFunnel';
import { useSendGTMEventOnce } from '../../../../hooks/useSendGTMEventOnce';

const TitleComponent = () => {
  const title = 'Din forespørsel er sendt!';
  return <Text variant={TextVariant.Header1}>{title}</Text>;
};

const ConfirmationContentComponent = () => {
  const history = useHistory();
  return (
    <>
      <Text variant={TextVariant.BodyText} style={{ marginTop: 8, marginBottom: 0 }}>
        Vi setter stor pris på at du tar kontakt med oss.
      </Text>
      <S.StyledTextBox>
        <Text variant={TextVariant.BodyTextHeader}>Henvendelsen er registrert i vårt system</Text>
        <Text variant={TextVariant.BodyText}>
          Normal behandlingstid er 1-2 virkedager. I perioder med stor pågang kan det ta lenger tid.
        </Text>
      </S.StyledTextBox>
      <S.StyledBottomButton
        text="Tilbake til NAF Senter"
        variant="outline"
        onClickFn={() => history.push('/bilverksted-og-tester')}
      />
    </>
  );
};

export const Confirmation = () => {
  useSendGTMEventOnce(funnelPayload('Servicekalkulator', 5), []);

  return (
    <S.StyledWrapper>
      <BreadCrumb paddingTop={false} />
      <S.DesktopWrapper>
        <S.StyledConfirmationTextContainer>
          <TitleComponent />
          <ConfirmationContentComponent />
        </S.StyledConfirmationTextContainer>
        <S.StyledConfirmationImageWrapper>
          <SentSuccessfully />
        </S.StyledConfirmationImageWrapper>
      </S.DesktopWrapper>
      <S.MobileWrapper>
        <S.StyledMobileHeader>
          <TitleComponent />
          <S.StyledConfirmationImageWrapper>
            <SentSuccessfully />
          </S.StyledConfirmationImageWrapper>
        </S.StyledMobileHeader>
        <ConfirmationContentComponent />
      </S.MobileWrapper>
    </S.StyledWrapper>
  );
};
