import React, { useState } from 'react';
import StepWizard from 'react-step-wizard';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@naf/button';
import { useHistory, useLocation } from 'react-router-dom';
import { Text, TextVariant } from '@naf/text';
import { ProgressBar } from '@naf/progress-bar';
import * as S from '../Styles';
import { SelectVehicleStep } from './selectVehicleStep/SelectVehicleStep';
import { SelectWorkProposalStep } from './selectWorkProposalStep/SelectWorkProposalStep';
import { CenterProductArticleType } from '../../../../../../types/centerProductArticleType';
import BreadCrumb from '../../../../components/breadcrumb/BreadCrumb';
import { useGTMDataLayer } from '../../../../hooks/useGTMDataLayer';
import { handleButtonClick } from '../ServiceCalculator.utils';

interface WizardProps {
  isMember: boolean;
  centerId: number;
  centerProductArticle: CenterProductArticleType;
}

export const Wizard = ({ isMember, centerId, centerProductArticle }: WizardProps) => {
  const history = useHistory();
  const location = useLocation();
  const dataLayer = useGTMDataLayer();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const { title } = (centerProductArticle as CenterProductArticleType) || {};
  const [progressBarCompletedPercent, setProgressBarCompletedPercent] = useState(25);
  const [progressBarLabel, setProgressBarLabel] = useState('Velg senter og bil');

  const updateProgressBarCompletedPercent = (value: number) => {
    setProgressBarCompletedPercent(value);
  };

  const updateProgressBarLabel = (value: string) => {
    setProgressBarLabel(value);
  };

  return (
    <S.StyledWrapper>
      <BreadCrumb paddingTop={false} />
      <Text variant={TextVariant.Header1}>{title}</Text>
      {(!isAuthenticated || !isMember) && (
        <Text variant={TextVariant.BodyText}>Tjenesten er kun tilgjengelig for NAF-medlemmer.</Text>
      )}
      {!isMember && (
        <Button
          variant="signature"
          onClick={() => {
            handleButtonClick(dataLayer, 'Bli medlem');
            history.push('/bli-medlem');
          }}
          style={{ marginRight: 16, marginTop: 16 }}
        >
          Bli medlem
        </Button>
      )}
      {!isAuthenticated && (
        <Button
          variant="outline"
          onClick={() => {
            handleButtonClick(dataLayer, 'Logg inn');
            loginWithRedirect({ appState: { returnTo: location.pathname } });
          }}
          style={{ marginTop: 16 }}
        >
          Logg inn
        </Button>
      )}
      {isAuthenticated && isMember && (
        <StepWizard
          transitions={{}}
          isLazyMount
          nav={<ProgressBar completedPercent={progressBarCompletedPercent} label={progressBarLabel} />}
        >
          <SelectVehicleStep
            nafCenterId={centerId}
            onUpdateProgressBarCompletedPercent={updateProgressBarCompletedPercent}
            onUpdateProgressBarLabel={updateProgressBarLabel}
          />
          <SelectWorkProposalStep
            onUpdateProgressBarCompletedPercent={() => updateProgressBarCompletedPercent(75)}
            onUpdateProgressBarLabel={() => updateProgressBarLabel('Velg utstyrspakke')}
          />
        </StepWizard>
      )}
    </S.StyledWrapper>
  );
};
