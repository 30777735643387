import React from 'react';
import { Controller, FieldValues, UseControllerProps, UseControllerReturn } from 'react-hook-form';
import { Label, Message } from '@naf/input';
import { Text, TextVariant } from '@naf/text';
import * as S from '../../Styles';

export const NafCenter = <T extends FieldValues>({
  control,
  defaultValue,
  name,
  rules,
  centers,
  errorMessage,
}: UseControllerProps<T> & {
  centers: { label: string; value: number }[];
  errorMessage: string;
}) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    defaultValue={defaultValue}
    render={({ field: { onChange, value } }: UseControllerReturn<T>) => (
      <S.StyledLabelArea>
        <Label>Velg NAF Senter</Label>
        <S.StyledSelect
          className="full-width"
          selected={centers.find((c) => c.value === value)}
          options={centers}
          handleSelect={(option) => {
            onChange(option.value);
          }}
          onSelect={(e) => {
            onChange(centers.find((item) => item.label.toLowerCase() === e.target.value.toLowerCase())?.value ?? '');
          }}
          error={!!errorMessage}
        />
        {!!errorMessage && <Message error>{errorMessage}</Message>}
        <Text variant={TextVariant.BodyText} style={{ marginBottom: 0, marginTop: 8 }}>
          Tjenesten er foreløpig kun tilgjengelig hos noen NAF-sentre
        </Text>
      </S.StyledLabelArea>
    )}
  />
);
