import React from 'react';
import { Controller, FieldValues, UseControllerProps, UseControllerReturn } from 'react-hook-form';
import { Label, Message } from '@naf/input';
import * as S from '../../Styles';

export const Mileage = <T extends FieldValues>({
  control,
  name,
  rules,
  errorMessage,
}: UseControllerProps<T> & { errorMessage: string }) => (
  <Controller
    name={name}
    rules={rules}
    control={control}
    render={({ field: { onChange, value } }: UseControllerReturn<T>) => (
      <S.StyledLabelArea>
        <Label>Kilometerstand</Label>
        <S.StyledInput
          onChange={(v) => {
            onChange(v);
          }}
          value={value}
          error={!!errorMessage}
          type="number"
        />
        {!!errorMessage && <Message error>{errorMessage}</Message>}
      </S.StyledLabelArea>
    )}
  />
);
