/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { FormProvider, useFormState } from 'react-hook-form';
import { Text, TextVariant } from '@naf/text';
import { UserInteraction, UserInteractionVariant } from '@naf/user-interaction';
import * as S from '../Styles';
import { useServiceCalculator } from '../context/ServiceCalculatorContext';
import VehicleIcon from '../../../../components/icons/VehicleIcon';
import useSelector from '../../../../redux/typedHooks';
import { Comments } from './Comments';
import { Confirmation } from './Confirmation';
import BreadCrumb from '../../../../components/breadcrumb/BreadCrumb';
import { funnelPayload } from '../../../../../../types/gtmFunnel';
import { useSendGTMEventOnce } from '../../../../hooks/useSendGTMEventOnce';
import { handleButtonClick } from '../ServiceCalculator.utils';
import { useGTMDataLayer } from '../../../../hooks/useGTMDataLayer';

interface ManualRequestProps {
  documentId: string;
}

export const ManualRequest = ({ documentId }: ManualRequestProps) => {
  const dataLayer = useGTMDataLayer();
  const { customer } = useSelector((state) => state.myMembership.customerInformation.data);
  const { vehicleForm, feedbackForm, sendFeedbackForm, resetState } = useServiceCalculator();
  const { isSubmitting, isSubmitted, isSubmitSuccessful } = useFormState({
    control: feedbackForm.control,
  });
  const { licensePlateNumber, mileage, vehicleName } = vehicleForm.getValues();
  const vehicleText = `${licensePlateNumber} ${vehicleName}, ${mileage} km`;

  const [isFeedbackSent, setIsFeedbackSent] = useState(false);

  const onSubmit = async () => {
    handleButtonClick(dataLayer, 'Send forespørsel');

    const { comments } = feedbackForm.getValues();
    if (customer?.email) {
      const isSent = await sendFeedbackForm(customer.email, comments, vehicleText, documentId);
      setIsFeedbackSent(isSent);
    }
  };

  useSendGTMEventOnce(funnelPayload('Servicekalkulator', 4), []);

  return isSubmitted && isSubmitSuccessful && isFeedbackSent ? (
    <Confirmation />
  ) : (
    <S.StyledWrapper>
      <S.StyledInnerContent>
        <BreadCrumb paddingTop={false} />
        <Text variant={TextVariant.Header1}>Servicekalkulator - Elbil</Text>
        <UserInteraction className="full-width" title="Kan ikke kalkulere prisen" variant={UserInteractionVariant.Info}>
          Det kan være flere årsaker til at vi ikke kan gi deg en pris nå. Send oss en forespørsel med beskrivelse av
          hva du ønsker, så kommer vi tilbake til deg med et tilbud per e-post.
        </UserInteraction>

        <Text variant={TextVariant.Header2} style={{ marginTop: 40 }}>
          Få serviceforslag og prisestimat på e-post
        </Text>
        <Text variant={TextVariant.BodyText} style={{ marginBottom: 32 }}>
          Normal behandlingstid er 1-2 virkedager. I perioder med stor pågang kan det ta lenger tid.
        </Text>
        <S.StyledVehicleText>
          <VehicleIcon />
          <Text variant={TextVariant.BodyTextHeader}>{vehicleText}</Text>
        </S.StyledVehicleText>
        <S.UserInfoContainer>
          <Text variant={TextVariant.BodyText} style={{ marginBottom: 32, marginTop: 8 }}>
            Din e-post:
          </Text>
          <Text variant={TextVariant.BodyText} style={{ fontWeight: 700, marginTop: 8 }}>
            {customer?.email}
          </Text>
        </S.UserInfoContainer>
        <FormProvider {...feedbackForm}>
          <form onSubmit={feedbackForm.handleSubmit(onSubmit)}>
            <Comments name="comments" control={feedbackForm.control} />
            <S.StyledBottomButton text="Send forespørsel" variant="signature" isLoading={isSubmitting} submit />
            {isSubmitted && !isFeedbackSent && (
              <S.StyledUserInteractionError
                title="Oops, nå kjørte vi oss fast"
                button={{
                  type: 'submit',
                  onClick: () => {
                    handleButtonClick(dataLayer, 'Prøv igjen');
                    feedbackForm.handleSubmit(onSubmit);
                  },
                  children: 'Prøv igjen',
                }}
                variant="error"
              />
            )}
          </form>
        </FormProvider>
      </S.StyledInnerContent>
      <S.StyledBottomButton
        text="Kalkulér på nytt"
        variant="outline"
        onClickFn={() => {
          handleButtonClick(dataLayer, 'Kalkulér på nytt');
          resetState();
        }}
      />
    </S.StyledWrapper>
  );
};
