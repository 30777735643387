import React from 'react';
import { Controller, FieldValues, UseControllerProps, UseControllerReturn } from 'react-hook-form';
import { Label, Message } from '@naf/input';
import { TextVariant } from '@naf/text';
import * as S from '../../Styles';

export const Vehicle = <T extends FieldValues>({
  control,
  name,
  rules,
  handleChange,
  errorMessage,
  vehicles,
  vehicleLookup,
  handleLookup,
  defaultValue,
}: UseControllerProps<T> & {
  errorMessage: string;
  vehicles: { label: string; value: string }[];
  handleChange: (label: string, value: string) => void;
  vehicleLookup: string;
  handleLookup: (input: string) => any;
}) => (
  <Controller
    name={name}
    rules={rules}
    control={control}
    defaultValue={defaultValue}
    render={({ field: { value, onChange }, fieldState }: UseControllerReturn<T>) => (
      <S.StyledLabelArea>
        <Label>Kjøretøy</Label>
        <S.StyledSelect
          className="full-width"
          placeholder="Søk på reg.nr."
          value={{ label: value, value }}
          selected={
            !fieldState.isTouched && defaultValue !== '' && value === ''
              ? vehicles.find((v) => v.value === defaultValue)
              : vehicles.find((v) => v.value === value) ?? { label: value, value }
          }
          options={vehicles}
          filterFunction={(inputValue) => {
            if (inputValue !== value) {
              onChange(inputValue);
              handleLookup(inputValue);
            }
          }}
          handleSelect={(option) => {
            if (option.value !== value) {
              handleChange(option.label, option.value);
              onChange(option.value);
            }
          }}
          error={!!errorMessage}
        />
        {!!errorMessage && <Message error>{errorMessage}</Message>}
        {value !== '' && !vehicles.find((v) => v.value === value) && (
          <Label variant={TextVariant.DataLabel} width={512}>
            {vehicleLookup && `Personbil: ${vehicleLookup}`}
          </Label>
        )}
      </S.StyledLabelArea>
    )}
  />
);
