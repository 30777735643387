import React from 'react';
import { Controller, FieldValues, UseControllerProps, UseControllerReturn } from 'react-hook-form';
import { Input, Label } from '@naf/input';
import { TextVariant } from '@naf/text';
import * as S from '../Styles';

export const Comments = <T extends FieldValues>({ control, name }: UseControllerProps<T>) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value } }: UseControllerReturn<T>) => (
      <S.StyledLabelArea style={{ marginBottom: 32 }}>
        <Label variant={TextVariant.DataLabel}>Beskrivelse av ønsket service</Label>
        <S.StyledTextArea>
          <Input
            onChange={(v) => {
              onChange(v);
            }}
            value={value}
            as="textarea"
            maxCharacters={500}
          />
        </S.StyledTextArea>
      </S.StyledLabelArea>
    )}
  />
);
