import { useCallback } from 'react';
import axios from 'axios';
import useSelector from '../redux/typedHooks';
import { useAuth0Token } from './useAuth0Token';
import {
  ServiceCalculatorOfferRequest,
  ServiceCalculatorOfferResponse,
  VehicleMaintenanceWizard,
} from '../../../types/ServiceCalculatorTypes';
import { CenterType } from '../../../types/centerType';

export const useServiceCalculatorApi = () => {
  const { apimBaseUrl, apimNafCenterApi, apimContentHub, apimFormApi, apimNafNoApi } = useSelector(
    (state) => state.application,
  );
  const { simpleToken } = useAuth0Token();

  const getHeaders = useCallback(
    () => ({ Authorization: `Bearer ${simpleToken}`, 'Ocp-Apim-Subscription-Key': apimContentHub }),
    [simpleToken, apimContentHub],
  );

  const getOfferFn = useCallback(
    async (request: ServiceCalculatorOfferRequest) => {
      try {
        const { data } = await axios.post<ServiceCalculatorOfferResponse>(
          `${apimBaseUrl}/${apimNafCenterApi}/service-calculator/offer`,
          request,
          {
            headers: getHeaders(),
          },
        );

        return data;
      } catch {
        return undefined;
      }
    },
    [apimBaseUrl, apimNafCenterApi, getHeaders],
  );

  const getOfferWithPossibleEquipmentFn = useCallback(
    async (request: VehicleMaintenanceWizard) => {
      try {
        const { data } = await axios.post<ServiceCalculatorOfferResponse>(
          `${apimBaseUrl}/${apimNafCenterApi}/service-calculator/offer-with-eq`,
          request,
          {
            headers: getHeaders(),
          },
        );

        return data;
      } catch {
        return undefined;
      }
    },
    [apimBaseUrl, apimNafCenterApi, getHeaders],
  );

  const getOfferWithSelectedWorkProposalFn = useCallback(
    async (request: VehicleMaintenanceWizard) => {
      try {
        const { data } = await axios.post<ServiceCalculatorOfferResponse>(
          `${apimBaseUrl}/${apimNafCenterApi}/service-calculator/offer-selected-work-proposal`,
          request,
          {
            headers: getHeaders(),
          },
        );

        return data;
      } catch {
        return undefined;
      }
    },
    [apimBaseUrl, apimNafCenterApi, getHeaders],
  );

  const sendFeedbackFormFn = useCallback(
    async (email: string, comments: string, vehicleText: string, documentId: string) => {
      try {
        const formData = new FormData();
        const formDataValue = {
          fields: [
            { id: 'epost', value: email },
            { id: 'description', value: `Forespørsel fra service calc. <br> ${vehicleText} <br> ${comments}` },
            { id: 'documentId', value: documentId },
          ],
        };
        formData.append('fields', new Blob([JSON.stringify(formDataValue)], { type: 'application/json' }));
        const { status } = await axios.post(`${apimBaseUrl}/${apimFormApi}/dynamic-form`, formData, {
          headers: getHeaders(),
        });
        return status < 400;
      } catch (e) {
        return false;
      }
    },
    [apimBaseUrl, apimFormApi, getHeaders],
  );

  const getAvailableCenters = useCallback(async () => {
    try {
      const { data } = await axios.get<CenterType[]>(
        `${apimBaseUrl}/${apimNafNoApi}/naf-centers?productSlugs=servicekalkulator-elbil`,
        {
          headers: getHeaders(),
        },
      );

      return data;
    } catch {
      return undefined;
    }
  }, [apimBaseUrl, apimNafNoApi, getHeaders]);

  return {
    getOfferFn,
    getOfferWithPossibleEquipmentFn,
    getOfferWithSelectedWorkProposalFn,
    sendFeedbackFormFn,
    getAvailableCenters,
  };
};
