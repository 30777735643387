import React from 'react';
import { Controller, FieldValues, UseControllerProps, UseControllerReturn } from 'react-hook-form';
import { Text, TextVariant } from '@naf/text';
import { Message } from '@naf/radio-button';
import RadioButton from '../../../../../components/RadioButton';
import * as S from '../../Styles';

export const WorkProposal = <T extends FieldValues>({
  control,
  name,
  rules,
  errorMessage,
  workProposals,
}: UseControllerProps<T> & { errorMessage: string; workProposals: any }) => (
  <Controller
    name={name}
    rules={rules}
    control={control}
    render={({ field: { onChange, value } }: UseControllerReturn<T>) => (
      <>
        <S.StyledRadioContainer>
          <Text variant={TextVariant.BodyText}>
            For å kunne estimere pris trenger vi å vite hvilken utstyrspakke bilen din har.
          </Text>
          {workProposals.map((workProposal) => (
            <RadioButton
              key={workProposal.value}
              onChange={() => onChange(workProposal.value)}
              checked={workProposal.value === value}
              label={workProposal.text}
            />
          ))}
        </S.StyledRadioContainer>
        <RadioButton
          onChange={() => onChange(0)}
          checked={value === 0}
          label="Jeg er usikker på utstyrspakken for min bil, men ønsker serviceforslag med pris tilsendt på e-post."
        />
        {!!errorMessage && <Message error>{errorMessage}</Message>}
      </>
    )}
  />
);
