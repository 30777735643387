import { SanityBlock } from '@dtp/membership-service-types/dist/sanity/sanity';
import { TableRowType } from '../src/client/components/block-content/components/types/components/Table';
import { DocumentTypeLabel } from './testType';

interface PriceType {
  campaignPrice: number | null;
  isCampaign: boolean;
  price: number;
}

export enum DisplayStyle {
  WithPricePrimaryButton = 'WithPricePrimaryButton',
  WithPriceSecondaryButton = 'WithPriceSecondaryButton',
  WithoutPrice = 'WithoutPrice',
}

export interface CenterProducts {
  booking?: {
    data: {
      blank: boolean;
      href: string | null;
      type: string;
    };
    type: string;
  };
  ingress: string | null;
  link?: {
    data: {
      slug: string;
      type: string;
    };
    type: string;
  };
  memberPrice: PriceType | null;
  name: string;
  nonMemberPrice: PriceType | null;
  productId: number;
  displayStyle: DisplayStyle | null;
  internalProductId: number | null;
  isBeta?: boolean;
  isNew?: boolean;
  isForMembersOnly?: boolean;
  linkText?: string;
  bookingText?: string;
}

export interface CenterArticleType {
  id: string;
  type: DocumentTypeLabel.nafCenterArticle;
  slug: string;
  address: string | null;
  body: SanityBlock[] | null;
  centerId: number;
  city: string | null;
  closingTime: string | null;
  closingTimeFriday: string | null;
  coordinates: {
    latitude: string;
    longitude: string;
  } | null;
  image: {
    source: string;
    publicId: string;
    version: string;
    srcSet: {
      jpgLarge: string;
    };
    altText: string | undefined;
    caption: string | undefined;
  } | null;
  ingress: string | null;
  informationText: string | null;
  informationTable: InformationTable;
  openingTime: string | null;
  openingTimeFriday: string | null;
  products: CenterProducts[];
  seoConfig: {
    introduction: string | null;
    isHiddenFromSearch: boolean;
    title: string | null;
  } | null;
  tags: {
    description: string;
    icon: string;
  }[];
  title: string;
  hasKeyDrop: boolean;
  hasKeySafe: boolean;
  keyDropLocation: string | null;
  campervanDisallowed: boolean;
  pvcAbove3500: boolean;
  additionalInformation: string[] | null;
}

export interface InformationTable {
  table: {
    title: string;
    table: { rows: TableRowType[] };
  };
}
